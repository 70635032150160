import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select", "vars", "template"];

  connect() {
    this.template = this.templateTarget.outerHTML;
    $(this.templateTarget).remove();
  }

  selected(e) {
    this.varsTarget.innerHTML = "";
    if (e.target.selectedIndex <= 0) return;
    var option = option = e.target.options[e.target.selectedIndex];
    var vars = option.dataset.vars.split(",");
    for (let index = 0; index < vars.length; index++) {
      const variable = vars[index];
      $(this.template.replace(/\$VAR/g, variable)).appendTo(this.varsTarget);
    }
  }

  remove(event) {
    $(event.target).parents("[data-target='nested-fields.field']").remove();
  }
}
