import { Controller } from "stimulus";

export default class extends Controller {
  submit(e) {
    const url = new URL(this.data.get("url") || location.href);
    this.element.querySelectorAll("input, select").forEach((input) => {
      const name = input.name;
      const value = input.value;
      url.searchParams.delete(name);
      url.searchParams.append(name, value);
    });
    App.load(url.toString());
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  keydown(e) {
    console.log(e);
    if (e.keyCode === 13) {
      this.submit(e);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }
}
