export default class {
  static turbolinksEnabled() {
    return window.Turbolinks && window.Turbolinks.supported;
  }

  static ready(callback) {
    if (this.turbolinksEnabled()) {
      return $(document).on("turbolinks:load", callback);
    } else {
      return $(callback);
    }
  }

  static load(url) {
    if (this.turbolinksEnabled()) {
      return Turbolinks.visit(url);
    } else {
      return (window.location.href = url);
    }
  }

  static reload() {
    if (this.turbolinksEnabled()) {
      return Turbolinks.visit(window.location.href);
    } else {
      return window.location.reload();
    }
  }
}
