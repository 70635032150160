var rails = require("@rails/ujs")
rails.start();
window.Rails = global.Rails = rails;

require("turbolinks").start();

require("@rails/activestorage").start();

import "vendor/bootstrap/bootstrap";
import "vendor/bootstrap/bootstrap.scss";


var moment = require("moment");
global.moment = window.moment = moment;

require("chartkick");
require("chart.js");

import "select2/dist/js/select2.full";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
$.fn.select2.defaults.set("theme", "bootstrap");

var urijs = require("urijs");
global.URI = window.URI = urijs;

import "daterangepicker";
import "daterangepicker/daterangepicker.css";

var fullcalendar = require("fullcalendar");
import "fullcalendar/dist/locales/ro";
global.FullCalendar = fullcalendar;
window.FullCalendar = fullcalendar;
import "fullcalendar/dist/fullcalendar.css";


import "vendor/audio-player/audio-player";
import "vendor/audio-player/audio-player.scss";

var app = require("scripts/app").default;
global.app = window.app = app;
global.App = window.App = app;

var autosize = require("autosize").default;
global.autosize = window.autosize = autosize;

import "controllers"
import "styles"
