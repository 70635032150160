import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  open(e) {
    const url = this.data.get("url");
    if (url == "" || url == undefined) {
      return;
    }
    app.load(url);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
}
