import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fieldsContainer", "field", "template"]

  connect() {
    this.index = this.fieldTargets.length
    this.template = this.templateTarget.innerHTML
    $(this.templateTarget).remove()
  }

  add() {
    this.index += 1;
    $(this.template.replace(/999/g, this.index)).appendTo(this.fieldsContainerTarget)
  }

  remove(event) {
    $(event.target).parents("[data-target='nested-fields.field']").remove()
  }
}
