import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    const ctrl = this;
    const calendar = new FullCalendar.Calendar(this.element, {
      header: {
        left: "title",
        center: "",
        right: "today prev,next",
      },
      firstDay: 1,
      locale: window.locale,
      themeSystem: "bootstrap4",
      footer: false,
      height: "auto",
      aspectRatio: 2,
      handleWindowResize: false,
      fixedWeekCount: false,
      eventSources: [
        {
          url: this.data.get("url"),
          color: "#e20074",
        },
      ],
      dateClick(e) {
        const events = this.getEvents().filter((ev) =>
          moment(ev.start).isSame(moment(e.date))
        );
        if (events.length === 0) {
          return Rails.ajax({
            url: ctrl.data.get("url").replace(".json", ".js"),
            type: "POST",
            data: "ivr_holiday[day]=" + e.dateStr,
            success: () => {
              return this.refetchEvents();
            },
          });
        } else {
          const event = events[0];
          return Rails.ajax({
            url: ctrl.data.get("url").replace(".json", "") + "/" + event.id,
            type: "DELETE",
            success: () => {
              return this.refetchEvents();
            },
          });
        }
      },
    });
    calendar.render();
  }
}
