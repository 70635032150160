import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    window.locale = document.body.dataset.locale || "ro"

    autosize(document.querySelectorAll('textarea[data-autosize]'));

    // radio buttons or checkboxes inside btn-group-toggle
    $('.btn-group-toggle input[type="radio"]').on("change", function () {
      // for each radio with the same name remove active class from their parents
      $(`input[name="${this.name}"]`).parent().removeClass("active");
      $(this).parent().toggleClass("active", this.checked);
    });

    // bootstrap
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $("[data-toggle='toast']").toast("show");
    $("[data-toggle='modal']").modal();

    // missing translation
    if (console && !console.log) {
      $(".translation_missing").each(function () {
        console.log($(this).attr("title"));
      });
    }

    // audio player
    if ($(".audio-player").is("*")) {
      $(".audio-player").mediaPlayer();
    }

    // propagate file name in custom input
    $("input[type=file]").on("change", function () {
      const name = $(this).val();
      $(this).next(".custom-file-label").html(name);
    });

    // chart dates update
    $("[data-chart-date-range]").each(function () {
      const el = $(this);
      const chart = Chartkick.charts[el.find(".chart > div").attr("id")];
      const url = chart.getDataSource();
      el.find("input").on("change", function () {
        const uri = URI(url);
        uri.addQuery("start", this.value.split(" - ")[0]);
        uri.addQuery("end", this.value.split(" - ")[1]);
        chart.updateData(uri.toString());
      });
    });

    // schedule - handle all day closed
    $("[data-schedule-day-closed]").on("change", function () {
      return $(this)
        .parents(".form-row")
        .find("select")
        .attr("disabled", this.checked);
    });

    // date-time pickers
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };

    $("[data-datetimerange]").daterangepicker({
      timePicker: true,
      timePickerIncrement: 5,
      autoUpdateInput: false,
      timePicker24Hour: true,
      locale: {
        format: "YYYY-MM-DD H:mm",
      },
      ranges,
    });

    $("[data-datetimerange]").on("apply.daterangepicker", function (
      ev,
      picker
    ) {
      const start = picker.startDate.format(picker.locale.format);
      const end = picker.endDate.format(picker.locale.format);
      picker.element.val(`${start} - ${end}`);
      picker.element.trigger("change");
    });

    // date range picker
    $("[data-daterange]").each(function () {
      const cfg = {
        timePicker: false,
        autoUpdateInput: false,
        locale: {
          format: "YYYY-MM-DD",
        },
        ranges,
      };
      $(this).daterangepicker($.extend(cfg, $(this).data()));
    });

    $("[data-daterange]").on("apply.daterangepicker", function (ev, picker) {
      const start = picker.startDate.format(picker.locale.format);
      const end = picker.endDate.format(picker.locale.format);
      picker.element.val(`${start} - ${end}`);
      picker.element.trigger("change");
    });

    $("[data-datetimepicker]").daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      autoUpdateInput: true,
      timePickerSeconds: false,
      locale: {
        format: "YYYY-MM-DD H:mm",
      },
    });

    // date picker
    $("[data-datepicker]").daterangepicker({
      timePicker: false,
      singleDatePicker: true,
      autoUpdateInput: false,
      locale: {
        format: "YYYY-MM-DD",
      },
    });

    $("[data-datepicker]").on("apply.daterangepicker", function (ev, picker) {
      const date = picker.startDate.format(picker.locale.format);
      picker.element.val(`${date}`);
      picker.element.trigger("change");
    });

    if ($("html").attr("variant")) {
      document.addEventListener("turbolinks:request-start", this.setVariant);
    }
  }

  disconnect() {
    document.removeEventListener("turbolinks:request-start", this.setVariant);
  }

  setVariant(event) {
    event.data.xhr.setRequestHeader("X-Rails-Variant", $("html").attr("variant"));
  }
}
