
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [""];

  connect() {
    if (this.data.get("characters") == "") return;
    const group = $(this.element);
    const wrapper = $("<div>").addClass("float-right");
    group.find("label").addClass("d-block").append(wrapper);
    for (let char of Array.from(
      this.data.get("characters").split("")
    )) {
      wrapper.append(
        $("<a>")
          .attr("href", "javascript:{}")
          .html(char)
          .addClass("btn-link ml-1 px-1")
      );
    }
    const input = group.find("input, textarea");
    return wrapper.find("a").on("click", function (e) {
      const cursorPos = input.prop("selectionStart");
      const v = input.val();
      const textBefore = v.substring(0, cursorPos);
      const textAfter = v.substring(cursorPos, v.length);
      input.val(textBefore + $(this).text() + textAfter);
      input.focus();
      return input[0].setSelectionRange(cursorPos + 1, cursorPos + 1);
    });
  }
}
