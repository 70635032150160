import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button", "nonce", "error"];

  connect() {
    braintree.dropin.create(this.config(), (error, instance) =>
      this.ready(error, instance)
    );
  }

  config() {
    return {
      authorization: this.data.get("token"),
      container: this.containerTarget,
      vaultManager: false,
      preselectVaultedPaymentMethod: false,
      translations: window.i18n.braintree,
      card: {
        cardholderName: {
          required: true,
        },
      },
    };
  }

  ready(error, instance) {
    if (error) {
      this.buttonTarget.classList.add("disabled");
    } else {
      this.braintree = instance;
    }
  }

  pay(e) {
    const originalLabel = this.buttonTarget.innerHTML;
    this.buttonTarget.innerHTML = "...";
    this.buttonTarget.classList.add("disabled");
    this.hideError();
    this.braintree.requestPaymentMethod((error, payload) => {
      if (error) {
        this.buttonTarget.classList.remove("disabled");
        this.buttonTarget.innerHTML = originalLabel;
        this.showError();
        return;
      }
      this.nonceTarget.value = payload.nonce;
      this.element.submit();
    });
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  showError(message) {
    message = message || window.i18n.payment.no_method_selected;
    this.errorTarget.classList.remove("d-none");
    this.errorTarget.innerHTML = message;
  }

  hideError() {
    this.errorTarget.classList.add("d-none");
    this.errorTarget.innerHTML = "";
  }
};
