import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [""];

  connect() {
    CodeMirror.fromTextArea(this.element, {
      mode: this.data.get("mime"),
      tabSize: 2,
      lineNumbers: true
    });
  }
}
