import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [""];

  load (e) {
    var ctrl = this;
    this.modal = $(this.modalHTML());
    this.modal.appendTo(document.body);
    this.modal.modal().on("hidden.bs.modal", function () {
      ctrl.modal.remove();
    });
    this.request();
    e.preventDefault();
    return false;
  }

  request () {
    var ctrl = this;
    Rails.ajax({
      type: "get",
      url: this.element.href,
      data: this.requestData(),
      complete: function (xhr) {
        if (xhr.status == 200) {
          ctrl.modal.find(".modal-body").html(xhr.responseText)
        } else {
          ctrl.modal.find(".modal-body").html("ERROR!")
        }
      },
    });
  }

  requestData () {
    if (this.data.get("form")) {
      return Rails.serializeElement($(this.data.get("form"))[0])
    }  else {
      return ""
    }
  }

  modalHTML () {
    var html =
      '\
      <div class="modal fade" tabindex="-1" role="dialog">\
        <div class="modal-dialog modal-lg" role="document">\
          <div class="modal-content">\
            <div class="modal-header">\
              <div>\
                <h4 class="modal-title">$TITLE</h4>\
              </div>\
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\
            </div>\
            <div class="modal-body">\
            <div class="text-center py-5"><div class="spinner-border"></div></div>\
            </div>\
          </div>\
        </div>\
      </div>\
    ';
    html = html.replace("$TITLE", this.data.get("title"));
    return html;
  }
}
