import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["department", "template", "addButton"];

  connect() {
    this.template = $(this.templateTarget).remove().html();
    this.index = this.departmentTargets.length - 1;
    if (this.index == -1) this.add();
  }

  add() {
    this.index += 1;
    $("<div>")
      .addClass("department-wrapper")
      .attr("data-target", "ivr-wizard--departments.department")
      .html(this.template.replace(/999/g, `${this.index}`))
      .insertBefore(this.addButtonTarget);
  }

  delete(e) {
    $(e.target).parents(".department-wrapper").remove();
  }
}
