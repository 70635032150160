import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  showNewIVRModal(e) {
    var url = e.target.href;
    var ctrl = this;
    this.newIVRModal = $(this.NewIVRModalHTML(e.target.innerText)).appendTo(document.body);
    this.newIVRModal.modal().on("hidden.bs.modal", function () { ctrl.newIVRModal.remove(); });
    this.newIVRIframe = $('<iframe id="new-ivr" src="' + url + '" frameborder="0" style="inset: 0px; left: 0; top: 0; width: 100%; height: 100%; border: none; margin: 0px; display: block"></iframe>');
    this.newIVRModal.find(".modal-body").empty().append(this.newIVRIframe);
    $(this.element).find('input[type="radio"]').each(function () { this.checked = false; $(this).parent().removeClass("active"); });
    this.newIVRIframe.on("load", function (e) { ctrl.iframeURLChanged(e.target.contentWindow.location.href); });
    this.newIVRModal.find(".use-ivr-btn").on("click", function () { ctrl.useCurrentIVR(); });

    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  iframeURLChanged(url) {
    if (url.includes("/ivr_steps")) {
      var ctrl = this;
      this.newIVRModal.find(".use-ivr-btn").removeClass("d-none");
      this.newIVRModal.find(".close-btn").addClass("d-none");
    }
  }

  useCurrentIVR() {
    var parts = this.newIVRIframe[0].contentWindow.location.href.split("/");
    var ivr_id = parts[parts.length - 2];
    $(this.element).find("#hidden_ivr_id").val(ivr_id);
    this.element.submit();
  }

  NewIVRModalHTML(title) {
    var html =
      '\
      <div class="modal fade modal-fullscreen" tabindex="-1" role="dialog">\
        <div class="modal-dialog" role="document">\
          <div class="modal-content">\
            <div class="modal-header">\
              <div>\
                <h4 class="modal-title">$TITLE</h4>\
              </div>\
              <button type="button" class="use-ivr-btn d-none btn btn-danger">$BUTTON</button>\
              <button type="button" class="close-btn close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>\
            </div>\
            <div class="modal-body">\
              <div class="text-center py-5"><div class="spinner-border"></div></div>\
            </div>\
          </div>\
        </div>\
      </div>\
    ';
    html = html.replace("$TITLE", title);
    html = html.replace("$BUTTON", window.i18n.ivr_instance_wizard.use_this_ivr);
    return html;
  }
}
